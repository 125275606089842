import React, { useEffect, useMemo, useRef, useState } from 'react';
import OutsideClickWrapper from '../OutsideClickWrapper/OutsideClickWrapper';
import { NavLink, useLocation, Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout } from '../../store/actions/auth';
import NotificationBlock from './NotificationBlock';
import {
  ROUTE_ENERGIYA_B2B,
  ROUTE_GAS_B2B_DASHBOARD,
  ROUTES_UNAUTHORIZAED_ALLOWED,
} from '../../constants';
import { isNull } from 'lodash';
import { Menu, Dropdown } from 'antd';
import Sidebar from '../Sidebar/Sidebar';
import SidebarProfile from '../Sidebar/SidebarProfile';
import SidebarGasB2C from '../Sidebar/SidebarGasB2C';

const handleClick = (e, disabled) => {
  if (disabled) e.preventDefault();
};

const Header = ({ logoutAction, history, location, userInfo }) => {
  const { gasB2c, gasB2b, eB2b } = userInfo;
  const energiyaB2bRoute = location.pathname.includes(ROUTE_ENERGIYA_B2B);
  const gasB2bRoute = location.pathname.includes(ROUTE_GAS_B2B_DASHBOARD);

  const activeGasB2c = !isNull(gasB2c) && userInfo.gasB2c;
  const activeGasB2b = !isNull(gasB2b) && userInfo.gasB2b;
  const activeEnergiyaB2b = !isNull(eB2b) && userInfo.eB2b;

  const renderDropdown = (activeGasB2b || activeEnergiyaB2b) && activeGasB2c;

  const [isVisibleAccount, setVisibleAccount] = useState(false);
  const { pathname } = useLocation();

  const isB2b = window.location.href.split('/')[3] === 'gas-b2b';
  const isEnergy = window.location.href.split('/')[3] === 'energiya';

  const [windowDimension, setWindowDimension] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);

  const handleCloseMenu = () => {
    setMenuOpen(false)
  }

  useEffect(() => {
    setWindowDimension(window.innerWidth);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobile = windowDimension <= 768;
  const isTablet = windowDimension <= 1279;
  const withMenu = isMobile || isTablet

  const getPath = pathname.split('/')[1];

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  useEffect(() => {
    const closeDrawer = (event) => {
      if (
        event.target.classList.contains('sidebar-link') ||
        event.target.parentElement.classList.contains('sidebar-link')
      ) {
        setMenuOpen(false);
      }
    };

    document.addEventListener('touchend', closeDrawer);
    return () => document.removeEventListener('touchend', closeDrawer);
  }, []);

  const menu = (
    <Menu>
      {isB2b || isEnergy ? (
        <Menu.Item>
          <Link
            to={activeGasB2c ? '/gas-b2c/dashboard' : '/gas-b2c'}
            // to='/gas-b2c/dashboard'
            className="dropdown__item"
          >
            Для дому
          </Link>
        </Menu.Item>
      ) : (
        <Menu.Item>
          <Link
            to={activeGasB2b ? '/gas-b2b/dashboard' : '/gas-b2b'}
            // to={activeGasB2b ? '/gas-b2b/dashboard' : '/energiya/dashboard'}
            className="dropdown__item"
          >
            Для бізнесу
          </Link>
        </Menu.Item>
      )}
    </Menu>
  );

  const checkActive = (currentPath) => {
    return pathname.includes(currentPath);
  };

  const HandlerLogout = () => {
    setVisibleAccount(false);

    logoutAction().then(() => {
      window.location.reload();
    });
  };

  const { firstName, lastName, email } = userInfo;

  const hiddenLinks = ROUTES_UNAUTHORIZAED_ALLOWED.includes(location.pathname);

  const renderLogo = () => {
    const host = window.location.host;
    const splitPath = host.split('.');

    const alternativeBrandRoute = ['b2b.tviygas.com.ua'];
    const alternativeBrandException = alternativeBrandRoute.some((item) => host === item);
    if (alternativeBrandException) return 'tviygas';

    const exceptionRoute = ['my.gaszbut.com.ua', 'gaszbut.dev.aurocraft.com', 'localhost:3000'];
    const filterException = exceptionRoute.some((item) => host === item);
    if (filterException) return 'default';


    return `${splitPath[1].slice(0, 2)}`;
  };

  const data = {
    withMenu,
    handleCloseMenu,
    checkActive,
    activeGasB2b,
    activeEnergiyaB2b,
    isB2b,
    hiddenLinks
  }

  const renderSidebar = useMemo(() => {
    if (getPath === 'profile') return <SidebarProfile />;
    if (getPath === 'gas-b2c') return <SidebarGasB2C />;
    if (getPath === 'gas-b2b' || getPath === 'energiya')
      return <Sidebar type={getPath === 'gas-b2b' ? 'gas' : 'energiya'} data={data} handleCloseMenu={handleCloseMenu} />;
    else return null;
  }, [pathname]);

  return (
    <header className="header">
      <div className="header__container">
        <div className="header__col">
          <Link to="/" className="header__link">
            <img
              className="header__logo"
              src={`/images/logos/${renderLogo()}-gas-logo.svg`}
              alt="logo"
            />
          </Link>
        </div>

        {hiddenLinks ? null : (
          <>
            {/*{((activeGasB2b && gasB2bRoute) || (activeEnergiyaB2b && energiyaB2bRoute)) && <div className="header__col center">*/}
            {((activeGasB2b && activeEnergiyaB2b && isB2b) || isEnergy) && (
              <div className="header__col center">
                {activeGasB2b && (
                  <NavLink
                    onClick={(e) => handleClick(e, !activeGasB2b)}
                    disabled={!activeGasB2b}
                    isActive={() => checkActive('gas')}
                    to="/gas-b2b/dashboard"
                    className="header-tab"
                  >
                    Постачання газу
                  </NavLink>
                )}

                {activeEnergiyaB2b && (
                  <NavLink
                    onClick={(e) => handleClick(e, !activeEnergiyaB2b)}
                    disabled={!activeEnergiyaB2b}
                    isActive={() => checkActive('energiya')}
                    to="/energiya/dashboard"
                    className="header-tab header-tab--energy"
                  >
                    Електропостачання
                  </NavLink>
                )}
              </div>
            )}

            {!isTablet && (
              <div className="header__col">
                <NotificationBlock />

                <OutsideClickWrapper close={setVisibleAccount}>
                  <div className="header-account">
                    <button
                      type="button"
                      onClick={() => setVisibleAccount(!isVisibleAccount)}
                      className="header-account-btn"
                    >
                      <div className="header-account-btn__inner">
                        <div className="header-account-btn__user">
                          {firstName || lastName ? `${firstName} ${lastName}` : email}
                        </div>
                        <div className="header-account-btn__position">адміністратор</div>
                      </div>

                      <div className="header-account-btn__box">
                        <img src="/icons/arrow-down.svg" alt="arrow-icon" />
                      </div>
                    </button>

                    {isVisibleAccount && (
                      <div className="header-account-list">
                        <Link
                          onClick={() => setVisibleAccount(false)}
                          to="/profile/my-profile"
                          type="button"
                          className="header-account-list-item"
                        >
                          <div className="header-account-list-item__box">
                            <img
                              className="header-account-list-item__icon"
                              src="/icons/user.svg"
                              alt="user-icon"
                            />
                          </div>
                          <div className="header-account-list-item__name">Мій профіль</div>
                        </Link>

                        <button
                          onClick={() => HandlerLogout()}
                          type="button"
                          className="header-account-list-item"
                        >
                          <div className="header-account-list-item__box">
                            <img
                              className="header-account-list-item__icon"
                              src="/icons/exit.svg"
                              alt="exit-icon"
                            />
                          </div>
                          <div className="header-account-list-item__name">Вийти</div>
                        </button>
                      </div>
                    )}
                  </div>
                </OutsideClickWrapper>
              </div>
            )}
          </>
        )}
        {isTablet && (
          <div className={`burger ${menuOpen ? 'opened' : ''}`} onClick={toggleMenu}>
            <div className="burger-content">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        )}
      </div>

      {isTablet && (
        <div className={`header-mobile-nav ${menuOpen ? 'opened' : ''}`}>
          <div className="mobile-dropdown">
            {renderDropdown && isMobile && (
              <Dropdown overlay={menu} trigger={['click']}>
                <div className="skew-dropdown">
                  <a className="dropdown__current" onClick={(e) => e.preventDefault()}>
                    {isB2b || isEnergy ? 'Для бізнесу' : 'Для дому'}
                    <img className="dropdown__caret" src="/icons/caret-down.svg" alt="" />
                  </a>
                </div>
              </Dropdown>
            )}
            <NotificationBlock />

            <OutsideClickWrapper close={setVisibleAccount}>
              <div className="header-account">
                <button
                  type="button"
                  onClick={() => setVisibleAccount(!isVisibleAccount)}
                  className="header-account-btn"
                >
                  <div className="header-account-btn__inner">
                    <div className="header-account-btn__user">
                      {firstName || lastName ? `${firstName} ${lastName}` : email}
                    </div>
                    <div className="header-account-btn__position">адміністратор</div>
                  </div>

                  <div className="header-account-btn__box">
                    <img src="/icons/arrow-down.svg" alt="arrow-icon" />
                  </div>
                </button>

                {isVisibleAccount && (
                  <div className="header-account-list">
                    <Link
                      onClick={() => setVisibleAccount(false)}
                      to="/profile/my-profile"
                      type="button"
                      className="header-account-list-item"
                    >
                      <div className="header-account-list-item__box">
                        <img
                          className="header-account-list-item__icon"
                          src="/icons/user.svg"
                          alt="user-icon"
                        />
                      </div>
                      <div className="header-account-list-item__name">Мій профіль</div>
                    </Link>

                    <button
                      onClick={() => HandlerLogout()}
                      type="button"
                      className="header-account-list-item"
                    >
                      <div className="header-account-list-item__box">
                        <img
                          className="header-account-list-item__icon"
                          src="/icons/exit.svg"
                          alt="exit-icon"
                        />
                      </div>
                      <div className="header-account-list-item__name">Вийти</div>
                    </button>
                  </div>
                )}
              </div>
            </OutsideClickWrapper>
          </div>

          {renderSidebar}

          {hiddenLinks ? null : (
            <>
              {/*{((activeGasB2b && gasB2bRoute) || (activeEnergiyaB2b && energiyaB2bRoute)) && <div className="header__col center">*/}

              {!isTablet && (
                <div className="header__col">
                  <NotificationBlock />

                  <OutsideClickWrapper close={setVisibleAccount}>
                    <div className="header-account">
                      <button
                        type="button"
                        onClick={() => setVisibleAccount(!isVisibleAccount)}
                        className="header-account-btn"
                      >
                        <div className="header-account-btn__inner">
                          <div className="header-account-btn__user">
                            {firstName || lastName ? `${firstName} ${lastName}` : email}
                          </div>
                          <div className="header-account-btn__position">адміністратор</div>
                        </div>

                        <div className="header-account-btn__box">
                          <img src="/icons/arrow-down.svg" alt="arrow-icon" />
                        </div>
                      </button>

                      {isVisibleAccount && (
                        <div className="header-account-list">
                          <Link
                            onClick={() => setVisibleAccount(false)}
                            to="/profile/my-profile"
                            type="button"
                            className="header-account-list-item"
                          >
                            <div className="header-account-list-item__box">
                              <img
                                className="header-account-list-item__icon"
                                src="/icons/user.svg"
                                alt="user-icon"
                              />
                            </div>
                            <div className="header-account-list-item__name">Мій профіль</div>
                          </Link>

                          <button
                            onClick={() => HandlerLogout()}
                            type="button"
                            className="header-account-list-item"
                          >
                            <div className="header-account-list-item__box">
                              <img
                                className="header-account-list-item__icon"
                                src="/icons/exit.svg"
                                alt="exit-icon"
                              />
                            </div>
                            <div className="header-account-list-item__name">Вийти</div>
                          </button>
                        </div>
                      )}
                    </div>
                  </OutsideClickWrapper>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </header>
  );
};

const mapStateToProps = (store) => {
  const { application } = store;

  return {
    auth: application.auth,
    userInfo: application.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logoutAction: (payload) => dispatch(logout(payload)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
