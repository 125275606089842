import React from 'react';
import { profileName } from "../../../services/config";


const arr = [
  {
    icon: 'icon5',
    name: 'Зручно управляти документами та формувати рахунки на оплату, не гаючи часу.',
  },
  {
    icon: 'icon3',
    name: 'Додавати членів команди для спільного керування споживанням компанії.',
  },
  {
    icon: 'icon2',
    name: 'Замовляти споживання газу на майбутні періоди;',
  },
  {
    icon: 'icon1',
    name: 'Управляти договорами вашої компанії та аналітикою споживання;',
  },
];

const SidebarStep1B2b = () => {
  return (
    <div className="registration-sidebar registration-sidebar__main">
      <h2 className="registration-sidebar-h2">{profileName}</h2>
      <span className="reg-sidebar-sub-title">В особистому кабінеті ви можете:</span>
      <div className="registration-sidebar-list">
        {arr.map(({ icon, name }) => (
          <div className="registration-sidebar-item" key={name}>
            <div className="sidebar-list-image-container">
              <img src={`/icons/${icon}.svg`} />
            </div>
            <span>{name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SidebarStep1B2b;
