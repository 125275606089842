import React from 'react';
import Button from '../../common/Button/Button';
import { profileEmail } from '../../services/config';

const SidebarSupport = ({ type = 'b2b' }) => {
  return type === 'b2b' ? (
    <div className="sidebar-support">
      <div className="sidebar-support__text">Підтримка споживачів</div>

      <button type="button" className="sidebar-support-button">
        <div className="sidebar-support-button__box">
          <img
            className="sidebar-support-button__photo"
            src="/images/operator.png"
            alt="operator"
          />
        </div>

        <div className="sidebar-support-button__inner">
          {/*<div className="sidebar-support-button__text">+38 099 454 23 44</div>*/}
          <div className="sidebar-support-button__text">${profileEmail}</div>
        </div>
      </button>
    </div>
  ) : (
    <div className="sidebar-support b2c">
      {/*<div className="sidebar-support-online">*/}
      {/*  <div className="sidebar-support__text">Підтримка Online 24/7</div>*/}
      {/*  <Button text="Онлайн допомога" iconLeft="online-help" className="black" />*/}
      {/*</div>*/}
      <div className="sidebar-support-phone">
        <div className="sidebar-support__text">Підтримка по телефону</div>
        {/*<div className="sidebar-support__text-phone">+38 099 454 23 44</div>*/}
      </div>
    </div>
  );
};

export default SidebarSupport;
