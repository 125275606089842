import { Link } from 'react-router-dom';
import React from 'react';
import { profileName } from '../../services/config';


const EmptyData = ({ energy }) => {
  return (
    <div className="empty-profile">
      <div className="empty-profile__top">
        <img className="" src={`/icons/${energy ? 'confirm.svg' : 'confirm1.svg'}`} />
        <div className={`empty-profile__title ${energy && 'empty-profile__title--energy'}`}>
          У вас немає активних договорів
        </div>
        <div className="empty-profile__subtitle">
          На даний момент в системі {profileName} немає жодного діючого договору. Ви можете уточнити
          всі деталі у персонального менеджера {profileName}.
        </div>
        <div className="empty-profile__subtitle">
          Для підписання нового Договору перейдіть у розділ “Документи”
        </div>
      </div>
      <div className="empty-profile__btn">
        <Link to={`${energy ? '/energiya' : '/gas-b2b'}/documents/contracts`}>
          <button type="button" className={`btn primary ${energy && 'primary-green'}`}>
            <span>До документів</span>
          </button>
        </Link>
      </div>
    </div>
  );
};

export default EmptyData;
