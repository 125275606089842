import React from 'react';
import {profileName} from "../../../services/config";


const arr = [
  {
    icon: 'icon5',
    name: 'Контролювати стан вашого балансу',
  },
  {
    icon: 'icon3',
    name: 'Делегувати доступ до особистого кабінету співробітникам',
  },
  {
    icon: 'icon2',
    name: 'Вести електронний документообіг через кабінет',
  },
  {
    icon: 'icon1',
    name: 'Аналізувати поточні та планувати майбутні витрати',
  },
];

const SidebarStep1 = () => {
  return (
    <div className="registration-sidebar registration-sidebar__main">
      <h2 className="registration-sidebar-h2">{profileName}</h2>
      <span className="reg-sidebar-sub-title">В особистому кабінеті ви можете:</span>
      <div className="registration-sidebar-list">
        {arr.map(({ icon, name }) => (
          <div className="registration-sidebar-item" key={name}>
            <div className="sidebar-list-image-container">
              <img src={`/icons/${icon}.svg`} />
            </div>
            <span>{name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SidebarStep1;
